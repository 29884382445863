<template>
	<div class="block sm:flex sm:gap-10 mt-10 sm:mt-20">
		<!-- About me portfolio image start -->
		<div class="w-full sm:w-1/4 mb-7 sm:mb-0">
			<img
				src="../../assets/images/profile.jpeg"
				class="rounded-xl w-96"
				alt=""
			/>
		</div>
		<!-- About me portfolio image end -->

		<!-- About me details start -->
		<div class="w-full sm:w-3/4 text-left">
			<p
				v-for="bio in bios"
				:key="bio.id"
				class="mb-4 text-ternary-dark dark:text-ternary-light text-lg"
			>
				{{ bio.bio }}
			</p>
		</div>
		<!-- About me details end -->
	</div>
</template>

<script>
export default {
	setup() {
		return {
			bios: [
				{
					id: 1,
					bio:
						'My name is Ilyes. I am calm, friendly and easy-going. I study computer science and I enjoy coding and working with talented people.',
				},
				{
					id: 2,
					bio:
						'I am facsinated by many aspects of technlogy. From hardware to software, I am interested in knowing the details of how things work and how to leverage that power to do something good.',
				},
				{
					id: 3,
					bio:
						'However, I always find myself attracted more by Web Development and recently I have been particularly interested in Web 3.0 technlogies using smart contracts for various applications like DeFi, which made me interested generally in the FinTech industry.',
				},
				{
					id: 3,
					bio:
						'My favorite programming languages that I use often are: JavaScript, Python and C++',
				},
				{
					id: 4,
					bio:
						'I also like natural languages as much as I do like programming languages. My native language is Arabic but I can speak English, French and Japanese fluently. I am also communicative in Chinese which is a skill that I have been working on recently.',
				},
			],
		};
	},
};
</script>
